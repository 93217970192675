import styles from "./TruncatedText.css";
import classes from "classnames";

const TruncatedText = ({ children, className, maxLines, style, title }) => (
    <div
        style={{ ...style, "--max-lines": maxLines }}
        className={classes(styles.truncatedText, className)}
        title={title}
    >
        {children}
    </div>
);

export default TruncatedText;
