import styles from "./StudySetsButton.css";
import classes from "classnames";
import { useSelector } from "react-redux";
import { selectActiveSet } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { IconButton } from "../../theming/components/eden-buttons";
import { StudySet as StudySetsIcon } from "@churchofjesuschrist/eden-icons";

export const StudySetsButton = ({
    activeSet,
    className,
    onClick,
    selectI18nStringById,
}) => {
    return (
        <IconButton
            className={classes(
                className,
                styles.studySetsButton,
                !activeSet && styles.noActiveSet
            )}
            data-testid={"study-set-tab"}
            id="study-set"
            onClick={onClick}
            title={selectI18nStringById("studySetsTitle")}
        >
            <StudySetsIcon />
            <div>{activeSet?.name}</div>
        </IconButton>
    );
};

const StudySetsButtonContainer = (props) => {
    const activeSet = useSelector(selectActiveSet);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <StudySetsButton
            activeSet={activeSet}
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default StudySetsButtonContainer;
