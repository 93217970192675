import styles from "./SidePanelHeader.css";
import classes from "classnames";
import { useSelectI18nStringById } from "../../../../../util/custom-hooks";
import {
    ArrowBack as ArrowIcon,
    Clear as CloseIcon,
} from "@churchofjesuschrist/eden-icons";
import { Text3 } from "@churchofjesuschrist/eden-text";
import { iconButtonFactory } from "../../../IconButton";
import PanelHeader from "../../../PanelHeader";
import TruncatedText from "../../../TruncatedText";

const CloseButton = iconButtonFactory(CloseIcon);
const ArrowLeftButton = iconButtonFactory(ArrowIcon);

CloseButton.displayName = "CloseButton";
ArrowLeftButton.displayName = "ArrowLeftButton";

export const SidePanelHeader = ({
    className,
    onBack,
    onClose,
    style,
    selectI18nStringById,
    title,
    titleRef,
    testId,
    truncated,
}) => (
    <PanelHeader
        className={classes(className, styles.sidePanelHeader)}
        style={style}
        testId={testId}
    >
        {onBack ? (
            <ArrowLeftButton
                onClick={onBack}
                title={selectI18nStringById("backButtonText")}
                data-testid={`${testId}-back`}
            />
        ) : (
            <div className={styles.headerSpace}></div>
        )}
        <div className={styles.headerTitle} ref={titleRef} tabIndex="-1">
            {title &&
                (truncated ? (
                    <TruncatedText
                        className={styles.headerTitle}
                        title={title}
                        data-testid={`${testId}-panel-title`}
                    >
                        {title}
                    </TruncatedText>
                ) : (
                    <Text3
                        className={styles.headerTitle}
                        title={title}
                        data-testid={`${testId}-panel-title`}
                    >
                        {title}
                    </Text3>
                ))}
        </div>
        {onClose && (
            <CloseButton
                onClick={onClose}
                title={selectI18nStringById("closePanelButtonText")}
                data-testid={`${testId}-panel-close`}
            />
        )}
    </PanelHeader>
);

const SidePanelHeaderContainer = (props) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <SidePanelHeader
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default SidePanelHeaderContainer;
