import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { setActivePanel } from "../../../../actions/sidePanel";
import PanelContent from "../../../PanelContent";
import SidePanelHeader from "../../components/SidePanelHeader";

export const EmptySidePanel = ({ children, onClose }) => (
    <>
        <SidePanelHeader onClose={onClose} />
        <PanelContent>{children}</PanelContent>
    </>
);

const EmptySidePanelContainer = (props) => {
    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    onClose: () => setActivePanel(null),
                },
                dispatch
            ),
        [dispatch]
    );

    return <EmptySidePanel {...actions} {...props} />;
};

export default EmptySidePanelContainer;
