import { useEffect, useState } from "react";
import styles from "./Notifications.css";
import classes from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
    updateSeenNotifications,
    setVisibleNotifications,
    updateAndRecordSeenNotifications,
} from "../../actions/notifications";
import { selectI18nStringById as selectI18nStringByIdBuilder } from "../../selectors";
import htmlReactParser from "html-react-parser";
import IconButton from "../IconButton";
import {
    ArrowBack as BackIcon,
    ForwardArrow as ForwardIcon,
} from "@churchofjesuschrist/eden-icons";
import Image from "@churchofjesuschrist/eden-image";
import { Alert } from "../../theming/components/eden-alert";
import {
    GhostButton,
    PrimaryButton,
    SecondaryButton,
} from "../../theming/components/eden-buttons";
import { Title4 } from "../../theming/components/eden-headings";
import { Text3 } from "../../theming/components/eden-text";

const Notifications = ({ className, notifications, onClose: handleClose }) => {
    const [index, setIndex] = useState(0);
    const [seenNotifications, setSeenNotifications] = useState([]);
    const selectI18nStringById = useSelector(selectI18nStringByIdBuilder);
    const dispatch = useDispatch();

    const { description, image, title, id, link } = notifications[index] || {};

    useEffect(() => {
        setSeenNotifications((seenNotifications) => [...seenNotifications, id]);
    }, [id]);

    if (!notifications.length) {
        handleClose && handleClose();

        return null;
    }

    const back = index !== 0 ? () => setIndex((index) => index - 1) : undefined;

    const next =
        index !== notifications.length - 1
            ? () => setIndex((index) => index + 1)
            : undefined;

    const onClose = () => {
        const allNotifications = notifications.map(({ id }) => id);
        dispatch(setVisibleNotifications([]));
        dispatch(updateAndRecordSeenNotifications(seenNotifications));
        dispatch(updateSeenNotifications(allNotifications));
        handleClose && handleClose();
    };

    const handleDismissAll = () => {
        const allNotifications = notifications.map(({ id }) => id);
        dispatch(setVisibleNotifications([]));
        dispatch(updateAndRecordSeenNotifications(allNotifications));
        handleClose && handleClose();
    };

    return (
        <Alert
            className={classes(className, styles.notifications)}
            onClose={onClose}
        >
            <div className={styles.layout}>
                <Title4 className={styles.title}>
                    {htmlReactParser(title)}
                </Title4>
                <div className={styles.contentContainer}>
                    {image && (
                        <Image
                            className={styles.image}
                            {...image}
                            sizes="60px"
                        />
                    )}
                    <div className={styles.content}>
                        <Text3 as="p" className={styles.description}>
                            {htmlReactParser(description)}
                        </Text3>
                        <div className={styles.buttonsBar}>
                            {link && (
                                <PrimaryButton
                                    className={styles.cta}
                                    href={link.href}
                                    target={link.target}
                                >
                                    {htmlReactParser(link.text)}
                                </PrimaryButton>
                            )}
                            {notifications.length <= 1 && (
                                <SecondaryButton
                                    className={styles.close}
                                    onClick={onClose}
                                >
                                    {selectI18nStringById("close")}
                                </SecondaryButton>
                            )}
                        </div>
                    </div>
                </div>
                {notifications.length > 1 && (
                    <div className={styles.navButtonsBar}>
                        <GhostButton
                            className={styles.dismissAll}
                            onClick={handleDismissAll}
                        >
                            {selectI18nStringById(
                                "dismissAllNotificationsButton"
                            )}
                        </GhostButton>
                        <div className={styles.navigationButtons}>
                            <IconButton
                                disabled={!back}
                                onClick={back}
                                size="small"
                                Icon={BackIcon}
                            />
                            <IconButton
                                disabled={!next}
                                onClick={next}
                                size="small"
                                Icon={ForwardIcon}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Alert>
    );
};

Notifications.displayName = "Notifications";

export default Notifications;
