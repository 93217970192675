import { Component } from "react";
import classes from "classnames";
import styles from "./PanelPlatformFooter.css";

class PanelPlatformFooter extends Component {
    componentDidMount() {
        this.footer = document.querySelector("platform-footer");

        if (this.footer) {
            if (this.props.forceMobile) {
                window.platformConfig.footerConfig.forceMobile = true;

                // alerting header/footer that forceMobile has been set to true
                document.dispatchEvent(new CustomEvent("checkcontext"));
            }

            if (this.props.panelFooter) {
                window.platformConfig.footerConfig = {
                    ...window.platformConfig.footerConfig,
                    forceMobile: true,
                    noNavigation: true,
                    social: { removeSocialLinks: true },
                };

                // alerting header/footer that forceMobile has been set to true
                document.dispatchEvent(new CustomEvent("checkcontext"));
            }

            this.self.append(this.footer);
        }
    }

    componentWillUnmount() {
        if (this.props.forceMobile) {
            window.platformConfig.footerConfig.forceMobile = false;
            document.dispatchEvent(new CustomEvent("checkcontext"));
        }

        if (this.props.panelFooter) {
            window.platformConfig.footerConfig = {
                ...window.platformConfig.footerConfig,
                forceMobile: false,
                noNavigation: false,
                social: { removeSocialLinks: false },
            };
            document.dispatchEvent(new CustomEvent("checkcontext"));
        }

        document.querySelector("#page").append(this.footer);
    }

    componentDidUpdate(prevProps) {
        if (this.props.forceMobile !== prevProps.forceMobile) {
            window.platformConfig.footerConfig.forceMobile =
                this.props.forceMobile;

            document.dispatchEvent(new CustomEvent("checkcontext"));
        }
    }

    render = () => (
        <div
            className={classes(
                styles.PanelPlatformFooter,
                this.props.className
            )}
            ref={(self) => (this.self = self)}
        >
            {/* Prevents the platform footer from flashing on page load
            https://medium.learnreact.com/the-style-tag-and-react-24d6dd3ca974 */}
            <style
                dangerouslySetInnerHTML={{
                    __html: "#page > platform-footer { display: none; }",
                }}
            />
        </div>
    );
}

export default PanelPlatformFooter;
