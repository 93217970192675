import { useContext, useMemo } from "react";
import styles from "./ErrorPanel.css";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { asyncCheckLogin } from "../../actions/system";
import analytics from "../../../util/analytics";
import {
    useMountEffect,
    useSelectErrorByStatus,
} from "../../../util/custom-hooks";
import GeneralContext from "../GeneralContext";
import { Text1 } from "@churchofjesuschrist/eden-text";
import { H2 } from "@churchofjesuschrist/eden-headings";
import { Primary } from "@churchofjesuschrist/eden-buttons";
import { ReadWidth } from "@churchofjesuschrist/eden-content-widths";

const ErrorPanel = ({
    crossLinkMode,
    status,
    lang,
    location,
    selectErrorByStatus,
}) => {
    const { header, title, showStatus, errorButtonText, ...otherStrings } =
        selectErrorByStatus(status);

    useMountEffect(() => {
        !crossLinkMode &&
            analytics.firePageViewEvent(asyncCheckLogin, {
                page: {
                    info: {
                        language: lang,
                    },
                    category: {
                        primary: "error",
                        type: status,
                    },
                },
            });
    });

    return (
        <ReadWidth className={styles.container}>
            <Text1 className={styles.header}>
                {header} {showStatus && status}
            </Text1>
            <H2 className={styles.title}>{title}</H2>
            {Object.entries(otherStrings).map(([key, value]) => (
                <Text1 className={styles.details} key={key}>
                    {value}
                </Text1>
            ))}
            <Primary href={`/study${location?.search}`}>
                {errorButtonText}
            </Primary>
        </ReadWidth>
    );
};

const ErrorPanelContainer = (props) => {
    const selectErrorByStatus = useSelectErrorByStatus();
    const { crossLinkMode, lang } = useContext(GeneralContext);

    const dispatch = useDispatch();
    const actions = useMemo(
        () => bindActionCreators({ asyncCheckLogin }, dispatch),
        [dispatch]
    );

    return (
        <ErrorPanel
            crossLinkMode={crossLinkMode}
            lang={lang}
            selectErrorByStatus={selectErrorByStatus}
            {...actions}
            {...props}
        />
    );
};

export default ErrorPanelContainer;
