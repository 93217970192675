/* eslint-disable react/display-name */
// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Component, forwardRef, useRef } from "react";
import classes from "classnames";
import styles from "./PanelContent.css";
import isBrowser from "is-in-browser";
import { useMergeRefs } from "use-callback-ref";

// https://stackoverflow.com/a/37474225/2591007
const convertLinesToPixels = (
    isBrowser
        ? () => {
              const iframe = document.createElement("iframe");
              iframe.src = "#";
              document.body.appendChild(iframe);
              let idoc = iframe.contentWindow.document;

              idoc.open();
              idoc.write(
                  "<!DOCTYPE html><html><head></head><body><span>a</span></body></html>"
              );
              idoc.close();

              let scrollLineHeight = idoc.body.firstElementChild.offsetHeight;
              document.body.removeChild(iframe);

              return (lines) => lines * scrollLineHeight;
          }
        : // this is only for serverside, so it shouldn't be used,
          // but 18 is the most common result of the above function
          (lines) => lines * 18
)();

class PanelContent extends Component {
    componentDidMount() {
        this.lastTouchPos = 0;

        this.props.forwardedRef.current.addEventListener(
            "touchmove",
            this.handleTouchMove,
            { passive: false }
        );
        this.props.forwardedRef.current.addEventListener(
            "wheel",
            this.normalizeWheel,
            { passive: false }
        );
    }

    componentWillUnmount() {
        this.props.forwardedRef.current.removeEventListener(
            "touchmove",
            this.handleTouchMove
        );
        this.props.forwardedRef.current.removeEventListener(
            "wheel",
            this.normalizeWheel
        );
    }

    handleKeyDown = (e) => {
        switch (e.keyCode) {
            case 38:
                e.normalizedDeltaY = -40;
                this.props.handleVirtualScroll(e);

                break;
            case 40:
                e.normalizedDeltaY = 40;
                this.props.handleVirtualScroll(e);

                break;
            default:
                break;
        }
    };

    handleTouchStart = (e) => {
        this.lastTouchPos = (e.targetTouches ? e.targetTouches[0] : e).clientY;
    };

    handleTouchMove = (e) => {
        if (this.props.disableVirtualDragging) return;

        const touchPos = (e.targetTouches ? e.targetTouches[0] : e).clientY;

        e.normalizedDeltaY = this.lastTouchPos - touchPos;
        this.lastTouchPos = touchPos;

        this.props.handleVirtualScroll(e);
    };

    normalizeWheel = (e) => {
        e.normalizedDeltaY =
            e.deltaMode === 1 ? convertLinesToPixels(e.deltaY) * 2 : e.deltaY;

        this.props.handleVirtualScroll(e);
    };

    getScrollbarStyles() {
        // this is currently not working in windows either
        // I'm leaving it so we have something in place to start from
        // mac has issues with delayed webkit scrollbar styling, so only style webkit scrollbars in windows
        return (
            isBrowser &&
            window.navigator.platform.startsWith("Win") &&
            styles.webkitScrollbar
        );
    }

    render() {
        const {
            children,
            className,
            forwardedRef,
            id,
            onKeyDownCapture,
            testId,
        } = this.props;

        return (
            <div
                id={id}
                onTouchStart={this.handleTouchStart}
                onKeyDown={this.handleKeyDown}
                onKeyDownCapture={onKeyDownCapture}
                ref={forwardedRef}
                className={classes(
                    styles.panelContent,
                    this.getScrollbarStyles(),
                    className
                )}
                data-testid={`${testId}-panel-content`}
            >
                {children}
            </div>
        );
    }
}

export default forwardRef((props, forwardedRef) => {
    const ref = useRef();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mergedRefs = isBrowser && useMergeRefs([forwardedRef, ref]);

    return <PanelContent {...props} forwardedRef={mergedRefs} />;
});
