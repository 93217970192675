import classes from "classnames";
import styles from "./PanelHeader.css";

const PanelHeader = ({ children, className, style, innerRef, testId }) => (
    <header
        ref={innerRef}
        className={classes(styles.panelHeader, className)}
        style={style}
        data-testid={`${testId}-panel-header`}
    >
        {children}
    </header>
);

export default PanelHeader;
