import styles from "./FallbackPanel.css";
import { useSelectI18nStringById } from "../../../../../util/custom-hooks";
import EmptySidePanel from "../EmptySidePanel";

const FallbackPanel = ({ onClose, titleRef }) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <EmptySidePanel onClose={onClose}>
            <section className={styles.noInternetRelatedContent}>
                <p
                    className={styles.noInternetRelatedContentTitle}
                    ref={titleRef}
                    tabIndex="-1"
                >
                    {selectI18nStringById("noInternetRelatedContentTitle")}
                </p>
                <p className={styles.noInternetRelatedContentText}>
                    {selectI18nStringById("noInternetRelatedContentText")}
                </p>
            </section>
        </EmptySidePanel>
    );
};

export default FallbackPanel;
