import { useMemo, useRef, useState } from "react";
import styles from "./FloatingPanel.css";
import classes from "classnames";
import loadable from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
    removeFloatingPanel,
    setActivePanel,
} from "../../../../actions/sidePanel";
import { selectSidePanelFocusOnMount } from "../../../../selectors";
import analytics from "../../../../../util/analytics";
import { useMountEffect } from "../../../../../util/custom-hooks";
import Loading from "../../../Loading";
import PanelContent from "../../../PanelContent";
import SidePanel from "../../../SidePanel";
import SidePanelHeader from "../../components/SidePanelHeader";

const AnnotationPanel = loadable(
    () =>
        import(/* webpackChunkName: 'annotation-panel' */ "../AnnotationPanel"),
    { fallback: <Loading /> }
);

const AssociatedMediaPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'associated-media-panel' */ "../AssociatedMediaPanel"
        ),
    { fallback: <Loading /> }
);

const CrossRefPanel = loadable(
    () => import(/* webpackChunkName: 'cross-ref-panel' */ "../CrossRefPanel"),
    { fallback: <Loading /> }
);

const DisambiguationPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'disambiguation-panel' */ "../DisambiguationPanel"
        ),
    { fallback: <Loading /> }
);

const EditorPanel = loadable(
    () => import(/* webpackChunkName: 'editor-panel' */ "../EditorPanel"),
    { fallback: <Loading /> }
);

const FootnotePanel = loadable(
    () => import(/* webpackChunkName: 'footnote' */ "../FootnotePanel"),
    { fallback: <Loading /> }
);

const MoveAnnotationToSetPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'move-annotation-to-set-panel' */ "../MoveAnnotationToSetPanel"
        ),
    { fallback: <Loading /> }
);

const componentLookup = {
    AnnotationPanel,
    AssociatedMediaPanel,
    CrossRefPanel,
    DisambiguationPanel,
    EditorPanel,
    FootnotePanel,
    MoveAnnotationToSetPanel,
};

const FloatingPanel = ({
    className,
    disambiguate,
    focusOnMount,
    focusRef,
    handleVirtualScroll,
    isTopPanel,
    isFirstPanel,
    openCrossLinkOverlay,
    panel,
    removeFloatingPanel,
    setActivePanel,
    sidePanelBottomBufferClassName,
    slideIn,
}) => {
    const fallbackRef = useRef();
    const titleRef = focusRef || fallbackRef;
    const [slideOut, setSlideOut] = useState();
    const [mounted, setMounted] = useState();

    const Component = componentLookup[panel.Component] || null;
    const panelStyles = styles[panel.Component.toLowerCase()];

    useMountEffect(() => {
        if (isTopPanel) {
            // check for and pause videos in other panels
            const relatedContentVideos = document.querySelectorAll(
                "[class*='sidePanel'] video"
            );

            relatedContentVideos?.forEach((video) => video.pause());
        }

        // delay is needed to make sure the initial css is loaded for the transition
        setTimeout(() => setMounted(true), 50);
    });

    const fireAnalytics = (type) => {
        if (Component !== CrossRefPanel && Component !== FootnotePanel) {
            return;
        }

        analytics.fireRelatedContentEvent({ type });
    };

    const onBack = () => {
        fireAnalytics("back");
        setSlideOut(true);
    };

    const onClose = () => {
        fireAnalytics("close");
        setActivePanel(null);
    };

    const setFocusOnMount = (event) => {
        if (event.target === event.currentTarget && focusOnMount) {
            titleRef.current?.focus();
        }
    };

    return (
        <SidePanel
            className={classes(
                className,
                styles.floatingPanel,
                slideIn && styles.slideIn,
                (slideOut || (!mounted && focusOnMount)) && styles.offScreen
            )}
            onTransitionEnd={slideOut ? removeFloatingPanel : setFocusOnMount}
            tabIndex="-1"
        >
            {Component === EditorPanel ? (
                <EditorPanel
                    handleVirtualScroll={handleVirtualScroll}
                    onClose={onClose}
                    {...panel.content}
                />
            ) : (
                <>
                    <SidePanelHeader
                        onBack={!isFirstPanel && onBack}
                        onClose={onClose}
                        title={panel.title}
                        truncated
                        titleRef={titleRef}
                        testId={`${panel.testId}`}
                    />
                    <PanelContent
                        handleVirtualScroll={handleVirtualScroll}
                        testId={`${panel.testId}`}
                    >
                        <Component
                            className={panelStyles}
                            disambiguate={disambiguate}
                            onClose={onClose}
                            closePanel={() => setSlideOut(true)}
                            openCrossLinkOverlay={openCrossLinkOverlay}
                            {...panel.content}
                        />
                        <div className={sidePanelBottomBufferClassName}></div>
                    </PanelContent>
                </>
            )}
        </SidePanel>
    );
};

const FloatingPanelContainer = (props) => {
    const focusOnMount = useSelector(selectSidePanelFocusOnMount);

    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    removeFloatingPanel,
                    setActivePanel,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <FloatingPanel focusOnMount={focusOnMount} {...actions} {...props} />
    );
};

export default FloatingPanelContainer;
