// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef } from "react";
import styles from "./Mask.css";
import classes from "classnames";

const handleKeyDown = (event) =>
    [37, 39, 38, 40].includes(event.keyCode) && event.preventDefault();

const Mask = ({ className, active, ...props }) => {
    const self = useRef();

    useEffect(() => {
        const selfElement = self.current;
        const preventScroll = (event) => event.preventDefault();

        selfElement.addEventListener("touchmove", preventScroll, {
            passive: false,
        });
        selfElement.addEventListener("wheel", preventScroll, {
            passive: false,
        });

        return () => {
            selfElement.removeEventListener("touchmove", preventScroll, {
                passive: false,
            });
            selfElement.removeEventListener("wheel", preventScroll, {
                passive: false,
            });
        };
    }, []);

    return (
        <div
            className={classes(styles.mask, active && styles.active, className)}
            onKeyDown={handleKeyDown}
            onTouchMove={(event) => event.preventDefault()}
            ref={self}
            {...props}
        />
    );
};

export default Mask;
