import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./SidePanelManager.css";
import classes from "classnames";
import loadable from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { bindActionCreators } from "redux";
import { setActivePanel } from "../../actions/sidePanel";
import {
    selectActivePanel,
    selectFloatingPanels,
    selectSidePanelFocusOnMount,
} from "../../selectors";
import {
    useSelectI18nStringById,
    useUpdateEffect,
} from "../../../util/custom-hooks";
import Loading from "../Loading";
import SidePanel from "../SidePanel";
import EmptySidePanel from "./sidePanels/EmptySidePanel";
import FallbackSidePanel from "./sidePanels/FallbackPanel";
import FloatingPanel from "./sidePanels/FloatingPanel";

const BookmarksPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'bookmarks-panel' */ "./sidePanels/BookmarksPanel"
        ).catch(() => ({ default: FallbackSidePanel })),
    {
        fallback: (
            <EmptySidePanel>
                <Loading />
            </EmptySidePanel>
        ),
    }
);

const OptionsPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'options-panel' */ "./sidePanels/OptionsPanel"
        ).catch(() => ({ default: FallbackSidePanel })),
    {
        fallback: (
            <EmptySidePanel>
                <Loading />
            </EmptySidePanel>
        ),
    }
);

const RelatedContentPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'related-content-panel' */ "./sidePanels/RelatedContentPanel"
        ).catch(() => ({ default: FallbackSidePanel })),
    {
        fallback: (
            <EmptySidePanel>
                <Loading />
            </EmptySidePanel>
        ),
    }
);

const StudySetsPanel = loadable(
    () =>
        import(
            /* webpackChunkName: 'study-sets-panel' */ "./sidePanels/StudySetsPanel"
        ).catch(() => ({ default: FallbackSidePanel })),
    {
        fallback: (
            <EmptySidePanel>
                <Loading />
            </EmptySidePanel>
        ),
    }
);

const TabLookup = {
    BookmarksPanel,
    OptionsPanel,
    RelatedContentPanel,
    StudySetsPanel,
};

const SidePanelManager = ({
    activePanel,
    className,
    disambiguate,
    floatingPanels = [],
    focusOnMount,
    handleVirtualScroll,
    onClose,
    openCrossLinkOverlay,
    page,
    sidePanelBottomBufferClassName,
    ...props
}) => {
    const focusRef = useRef();
    const lastTab = useRef(null);
    const [renderedPanel, setRenderedPanel] = useState(activePanel);
    const [renderedFloatingPanels, setRenderedFloatingPanel] =
        useState(floatingPanels);
    const [closing, setClosing] = useState(null);

    const updateRenderedPanels = useCallback(() => {
        setClosing(null);
        setRenderedPanel(activePanel);
        setRenderedFloatingPanel(floatingPanels);
    }, [activePanel, floatingPanels]);

    useUpdateEffect(() => {
        activePanel || floatingPanels.length
            ? updateRenderedPanels()
            : setClosing(true);
    }, [activePanel, floatingPanels]);

    useEffect(() => {
        page === "library" &&
            activePanel === "RelatedContentPanel" &&
            onClose();
    }, [page, activePanel, onClose]);

    const Tab = TabLookup[renderedPanel] || null;
    const firstTab = lastTab.current === null && !!Tab;

    useEffect(() => {
        lastTab.current = Tab;
    }, [Tab]);

    const setFocusOnMount = (event) => {
        if (event.target === event.currentTarget && focusOnMount) {
            focusRef.current?.focus();
        }
    };

    const setFocus = useCallback(() => focusRef.current?.focus(), []);

    return (
        <>
            <SidePanel
                className={classes(
                    className,
                    styles.sidePanel,
                    !activePanel && styles.closed
                )}
                onTransitionEnd={
                    closing ? updateRenderedPanels : setFocusOnMount
                }
            >
                {Tab && (
                    <Tab
                        disambiguate={disambiguate}
                        handleVirtualScroll={handleVirtualScroll}
                        onClose={onClose}
                        focusRef={focusRef}
                        page={page}
                        setFocus={
                            focusOnMount &&
                            !firstTab &&
                            !renderedFloatingPanels.length &&
                            setFocus
                        }
                        sidePanelBottomBufferClassName={
                            sidePanelBottomBufferClassName
                        }
                        {...props}
                    />
                )}
            </SidePanel>
            <div
                className={classes(
                    className,
                    styles.floatingPanels,
                    styles.sidePanel,
                    !floatingPanels.length && styles.closed
                )}
            >
                {renderedFloatingPanels
                    .filter(Boolean)
                    .map((panel, index, array) => (
                        <FloatingPanel
                            className={styles.sidePanel}
                            sidePanelBottomBufferClassName={
                                sidePanelBottomBufferClassName
                            }
                            disambiguate={disambiguate}
                            handleVirtualScroll={handleVirtualScroll}
                            isFirstPanel={index === 0 && !renderedPanel}
                            isTopPanel={array.length - 1 === index}
                            key={panel.key}
                            openCrossLinkOverlay={openCrossLinkOverlay}
                            panel={panel}
                            page={page}
                            slideIn={!!renderedPanel || index !== 0}
                            focusRef={
                                !(!!renderedPanel || index !== 0) && focusRef
                            }
                        />
                    ))}
            </div>
        </>
    );
};

const SidePanelManagerContainer = (props) => {
    const location = useLocation();
    const activePanel = useSelector(selectActivePanel);
    const floatingPanels = useSelector((state) =>
        selectFloatingPanels(state, location)
    );
    const focusOnMount = useSelector(selectSidePanelFocusOnMount);
    const selectI18nStringById = useSelectI18nStringById();

    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    onClose: () => setActivePanel(null),
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <SidePanelManager
            activePanel={activePanel}
            floatingPanels={floatingPanels}
            focusOnMount={focusOnMount}
            selectI18nStringById={selectI18nStringById}
            {...actions}
            {...props}
        />
    );
};

export default SidePanelManagerContainer;
