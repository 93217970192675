import classes from "classnames";
import styles from "./SidePanel.css";

const SidePanel = ({ children, className, Component = "aside", ...props }) => (
    <Component className={classes(styles.sidePanel, className)} {...props}>
        {children}
    </Component>
);

export default SidePanel;
