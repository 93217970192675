class ScrollLogic {
    _platformHeader = undefined;
    _overrideHeaderObserver = false;
    _headerObserver = undefined;
    _scrollContainer = undefined;
    _history = undefined;
    _headerVisible = true;
    _preventVirtualScroll = false;

    constructor({ history, defaultSidePanelRef, crossLinkMode } = {}) {
        this._scrollContainer = document.documentElement;
        this._platformHeader = document.querySelector("#header-wrapper");
        this._defaultSidePanelRef = defaultSidePanelRef;
        this._history = history;
        this._preventVirtualScroll = crossLinkMode;

        this._headerObserver = new IntersectionObserver(
            this.handleHeaderObserver,
            {
                root: null,
                // Give small buffer at top of page to allow scrolling down when zoomed in and getting rounding errors
                rootMargin: "-3px",
            }
        );

        this._headerObserver.observe(this._platformHeader);

        this._historyCleanup = this._history.listen((location, action) => {
            action.toUpperCase() === "POP" && this.overrideHeaderObserver();
            action.toUpperCase() === "PUSH" &&
                location.hash &&
                this.scrollToHash();
        });

        document.addEventListener("DOMContentLoaded", this.resetScrollPosition);
    }

    cleanup = () => {
        this._headerObserver.disconnect();
        this._historyCleanup();
        document.removeEventListener(
            "DOMContentLoaded",
            this.resetScrollPosition
        );
    };

    resetScrollPosition = () => {
        return (
            this._history.action.toUpperCase() === "PUSH" &&
            (this._history.location.hash
                ? this.scrollToHash()
                : this.handleScrollToTop())
        );
    };

    getContentTopOffset = (htmlElement, currentOffset = 0) =>
        htmlElement.id === "content" || !htmlElement.offsetParent
            ? currentOffset
            : this.getContentTopOffset(
                  htmlElement.offsetParent,
                  currentOffset + htmlElement.offsetTop
              );

    overrideHeaderObserver = () => {
        this._overrideHeaderObserver = true;
    };

    scrollToHash = () => {
        if (!this._history.location.hash) {
            this.handleScrollToTop();

            return;
        }

        let activeVerse = this._history.location.hash.slice(1);
        let hash = /^\d/.test(activeVerse) ? `p${activeVerse}` : activeVerse;
        let escapedHashSelector = `#${hash.replace(/(\W)/g, "\\$1")}`;
        let cleanedHashSelector = `#${hash.replace(/[^\w-].*$/, "")}`;
        let activeItem = document.querySelector(
            `${escapedHashSelector}, ${cleanedHashSelector}`
        );

        if (activeItem) {
            // Set a flag so _headerObserver knows not to undo this scroll
            this.overrideHeaderObserver();
            this._scrollContainer.scrollTop =
                this.getContentTopOffset(activeItem) +
                this._platformHeader.clientHeight;
        }
    };

    handleScrollToTop = () => {
        if (this._scrollContainer.scrollTop > this._platformHeader.clientHeight)
            this._scrollContainer.scrollTop = this._platformHeader.clientHeight;
    };

    handleHeaderObserver = (entries) => {
        // Don't move the header if a hash scroll has just taken place
        if (this._overrideHeaderObserver) {
            this._overrideHeaderObserver = false;
        } else if (this._defaultSidePanelRef?.current) {
            this._defaultSidePanelRef.current.scrollTop = 0;
        }

        this._headerVisible = entries[0].isIntersecting;
    };

    handleVirtualScroll = (event) => {
        if (this._preventVirtualScroll) return;

        const headerHeight =
            this._platformHeader.getBoundingClientRect().bottom +
            this._scrollContainer.scrollTop;
        const sidePanel = event.currentTarget;

        const scrollingDown = event.normalizedDeltaY > 0;
        const atContentTop = this._scrollContainer.scrollTop <= headerHeight;
        const atSidebarTop = sidePanel.scrollTop === 0;
        const atSidebarBottom =
            sidePanel.scrollTop + sidePanel.clientHeight ===
            sidePanel.scrollHeight;

        event.preventDefault();

        // The header takes up a lot of space so we want to minimize how often it's visible on the page.
        // The sidebars force it off the page when being scrolled down and don't allow it back on the page
        // if the content is not already at the top.
        if (!scrollingDown && !atSidebarTop) {
            sidePanel.scrollTop += event.normalizedDeltaY;
        } else if (
            this._headerVisible ||
            (!scrollingDown && atSidebarTop && atContentTop)
        ) {
            this._scrollContainer.scrollTop += event.normalizedDeltaY;
        } else if (scrollingDown && !atSidebarBottom) {
            sidePanel.scrollTop += event.normalizedDeltaY;
        }
    };

    scrollHeaderOffScreen = () => {
        this._headerVisible &&
            this._scrollContainer.scrollTo({
                top: this._platformHeader.clientHeight,
                left: 0,
                behavior: "smooth",
            });
    };
}

export default ScrollLogic;
